<template>
  <div class="p-3 login-form">
    <form class="form-signin">
      <img class="mb-4" src="..\..\public\android-chrome-512x512.png" alt width="72" height="72" />
      <h1 class="h3 mb-1 font-weight-normal">Thanks for being here</h1>
      <label for="loginForm.password">Please provide the unique identifier</label>
      <input type="password" v-model="password" class="form-control" required="required" />
      <span class="error" v-if="invalidInput">Required</span>
      <button
        type="button"
        class="btn btn-lg btn-primary btn-block mt-3"
        v-on:click="login()"
      >Sign In</button>
      <p class="mt-3 mb-3 text-muted">&copy; 2020</p>
    </form>

    <!-- <Vuecard>
      <template v-slot:header>Thanks for being here.</template>
      <form method="post">
        <label for="loginForm.password">Please provide the unique identifier provided.</label>
        <input type="password" v-model="password" class="form-control" required="required" />
        <span class="error" v-if="invalidInput">Required</span>
      </form>
      <template v-slot:footer>
        <button
          type="button"
          class="btn btn-primary btn-sm float-right"
          v-on:click="login()"
        >Sign In</button>
      </template>
    </Vuecard>-->
  </div>
</template>
 
     
<script>
import Vuecard from "../components/Vuecard"
import Swal from "sweetalert2"
export default {
  data() {
    return {
      password: null,
      invalidInput: false,
    }
  },
  components: {
    Vuecard,
  },
  computed: {},
  watch: {
    password: function (nw, old) {
      this.invalidInput = !(nw !== null && nw !== "")
    },
  },
  methods: {
    validate() {
      this.invalidInput = this.password === null || this.password === ""
      return this.invalidInput
    },
    login() {
      if (this.validate()) {
        return
      }

      this.$store.dispatch("login", {
        password: this.password,
      })
    },
    logout() {
      this.$store.dispatch("reset")
    },
  },
  beforeMount() {
    this.logout()
  },
}
</script>
<style lang="scss" scoped>
.login-form {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  vertical-align: middle;
  img {
    vertical-align: middle;
    border-style: none;
  }
  .form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
  }
}
</style>  