<template>
  <div class="card shadow overflow-auto" :id="componentId">
    <div class="card-header bg-primary text-white font-weight-bolder" v-if="hasHeaderSlot">
      <slot name="header"></slot>
    </div>
    <div class="card-body d-flex flex-column bs4-base-card-template-body" v-if="hasContent">
      <slot></slot>
    </div>
    <div class="card-footer" v-if="hasFooterSlot">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      componentId: null
    }
  },
  mounted: function() {
    this.componentId = "vue-base-card-" + this._uid
  },
  computed: {
    hasContent: function() {
      return !!this.$slots.default
    },
    hasFooterSlot: function() {
      return !!this.$slots.footer
    },
    hasHeaderSlot: function() {
      return !!this.$slots.header
    }
  }
}
</script>